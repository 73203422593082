import dayjs from 'dayjs';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { BookingData, emptyBooking } from 'common';

import { batchCreateBookings } from '../clientAPI/bookingsAPI';
import { isWithinOperatingHours } from '../helpers/bookingsHelper';
import { getBookingSlotTimeSequence } from './draftBookings';
import {
  buildSecondBookingToCreate,
  processCreateFrontAndBackBookings,
  slotTimeAfterGap,
} from './frontAndBackBookings';
import {
  dividePlayersIntoSlotTimes,
  getBookingsToBeCreated,
  hasBookingsStructureChanged,
} from './helpers/groupBookingHelpers';
import {
  copyFromOldToNewLinkedBooking,
  deleteAllLinkedBookings,
  getClashingBookings,
  getSortedLinkedBookings,
  reorderDraftBookingsAfterMove,
} from './linkedBookings';

const createGroupBooking = async (
  newBooking: BookingData,
  oldBooking: BookingData,
  allBookings: BookingData[],
  slotCount: number,
  saveAsFrontAndBackBooking: boolean,
  gap: number,
): Promise<boolean> => {
  const uuid = uuidv4();
  const newBookings: BookingData[] = getBookingsToBeCreated(
    newBooking,
    saveAsFrontAndBackBooking ? slotCount / 2 : slotCount, // If it's a front and back booking we half the slotCount as slotCount is including both front & back slots
    uuid,
  );

  return await processCreateGroupBookings(
    newBookings,
    [oldBooking],
    allBookings,
    saveAsFrontAndBackBooking,
    gap,
  );
};

const processCreateGroupBookings = async (
  bookingsToBeCreated: BookingData[],
  oldBookings: BookingData[],
  allBookings: BookingData[],
  saveAsFrontAndBackBooking: boolean,
  gap: number,
) => {
  const clashingBookings = await getClashingBookings(
    [
      ...bookingsToBeCreated,
      ...(saveAsFrontAndBackBooking
        ? bookingsToBeCreated.map((frontOrBack) =>
            buildSecondBookingToCreate(
              frontOrBack,
              frontOrBack.slot,
              frontOrBack.linkId,
              slotTimeAfterGap(frontOrBack.slotDate, gap),
            ),
          )
        : []),
    ],
    oldBookings,
    allBookings,
  );
  if (clashingBookings.length > 0) {
    window.alert(`There are existing bookings in the selected time slots. Move existing bookings and try again. 
            ${clashingBookings.map(
              (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
            )}`);

    return false;
  }

  const clashWithOperatingHours = bookingsToBeCreated.map((booking) => {
    return !isWithinOperatingHours(booking);
  });

  const clashingOperatingHoursBookings = bookingsToBeCreated.filter(
    (_, index) => clashWithOperatingHours[index],
  );

  // Add a message about clashing bookings with operating hours and ask the user if they want to overwrite.
  if (clashingOperatingHoursBookings.length > 0) {
    const affirm =
      window.confirm(`The following bookings are outside of operating hours. Would you like to book anyway? 
            ${clashingOperatingHoursBookings.map(
              (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
            )}`);

    if (!affirm) return false;
  }

  if (saveAsFrontAndBackBooking) {
    const createPromises = bookingsToBeCreated.map((booking) => {
      // As we are creating linked bookings from bulk create button, there are no existing bookings.
      // So we can pass an empty booking and an empty array of bookings.
      // The function will behave as expected as these two params are used to calculate draft number but
      // when creating in bulk, we only create bookings as confirmed bookings.
      return processCreateFrontAndBackBookings(
        booking,
        oldBookings,
        allBookings,
        slotTimeAfterGap(booking.slotDate, gap),
        booking.linkId,
      );
    });
    const createAllSuccess = await Promise.all(createPromises);

    // If create booking failed we don't want to execute further, so return & exit
    if (!createAllSuccess.every((success) => success)) {
      window.alert('Creating multiple records failed!');
      return false;
    }
  } else {
    const batchedBookings: BookingData[] = bookingsToBeCreated.map((booking) => {
      const bookingSlotTimeSequence = getBookingSlotTimeSequence(
        booking,
        oldBookings.find((oldBooking) => oldBooking.key === booking.key) ?? emptyBooking,
        allBookings,
      );
      return { ...booking, bookingSlotTimeSequence };
    });
    const batchCreateSuccess = await batchCreateBookings(batchedBookings);

    // If create booking failed we don't want to execute further, so return & exit
    if (!batchCreateSuccess) {
      window.alert('Creating multiple records failed!');
      return false;
    }
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
};

const editGroupBooking = async (
  booking: BookingData,
  oldBooking: BookingData,
  allBookings: BookingData[],
  slotCount: number,
  saveAsFrontAndBackBooking: boolean,
  gap: number,
): Promise<boolean> => {
  const correctSlotCount = saveAsFrontAndBackBooking ? slotCount / 2 : slotCount; // If it's a front and back booking we half the slotCount as slotCount is including both front & back slots
  const teamSizes = dividePlayersIntoSlotTimes(booking.totalPlayers, correctSlotCount).sort();
  const oldBookings: BookingData[] = await getSortedLinkedBookings(booking.linkId);

  // If the booking structure was changed or is a front&back booking, then we will update booking structure & exit execution
  if (hasBookingsStructureChanged(booking, oldBooking, oldBookings, teamSizes)) {
    return await updateGroupBookings(
      booking,
      oldBookings,
      allBookings,
      correctSlotCount,
      saveAsFrontAndBackBooking,
      gap,
    );
  }

  const newBooking = copyFromOldToNewLinkedBooking(oldBookings[0], booking);
  const newBookings: BookingData[] = getBookingsToBeCreated(
    newBooking,
    correctSlotCount,
    booking.linkId,
  );

  const batchedBookings = newBookings.map((booking, i) => ({
    ...booking,
    bookingSlotTimeSequence: oldBookings[i].bookingSlotTimeSequence,
  }));
  const batchCreateSuccess = await batchCreateBookings(batchedBookings);

  if (!batchCreateSuccess) {
    window.alert('Editing multiple records failed!');
    return false;
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
};

const moveGroupBooking = async (
  booking: BookingData,
  allBookings: BookingData[],
  slotCount: number,
  saveAsFrontAndBackBooking: boolean,
  gap: number,
): Promise<boolean> => {
  // When moving bookings there are 2 key things to perform.
  //  1. Move the bookings to the new slot and gap
  //  2. Update the group booking structure if needed

  // First, we need to find all the bookings linked to this booking
  const oldBookings = await getSortedLinkedBookings(booking.linkId);

  // Calculate and update the bookings with their new slot dates and group structure
  return await updateGroupBookings(
    booking,
    oldBookings,
    allBookings,
    saveAsFrontAndBackBooking ? slotCount / 2 : slotCount,
    saveAsFrontAndBackBooking,
    gap,
  );
};

const updateGroupBookings = async (
  booking: BookingData,
  oldBookings: BookingData[],
  allBookings: BookingData[],
  slotCount: number,
  saveAsFrontAndBackBooking: boolean,
  gap: number,
) => {
  // Find the amount the booking has moved
  const timeDifference = dayjs(booking.slotDate).diff(
    dayjs(oldBookings.find((oldBooking) => _.isEqual(oldBooking.key, booking.key))?.slotDate),
    'ms',
  );

  // Find the first or second F&B booking depending on the gap
  const bookingToUpdate =
    gap >= 0 ? oldBookings[0] : oldBookings[Math.floor(oldBookings.length / 2)];

  // Apply the time difference to the found booking
  const newBooking: BookingData = {
    ...copyFromOldToNewLinkedBooking(bookingToUpdate, booking),
    slotDate: dayjs(bookingToUpdate.slotDate).add(timeDifference, 'ms').toDate(),
  };

  const newBookings: BookingData[] = getBookingsToBeCreated(newBooking, slotCount, booking.linkId);

  const tempNewFrontAndBack: BookingData[] = saveAsFrontAndBackBooking
    ? newBookings.map((frontOrBack) =>
        buildSecondBookingToCreate(
          frontOrBack,
          frontOrBack.slot,
          frontOrBack.linkId,
          slotTimeAfterGap(frontOrBack.slotDate, gap),
        ),
      )
    : [];
  const clashingBookings = await getClashingBookings(
    [...newBookings, ...tempNewFrontAndBack],
    oldBookings,
    allBookings,
  );
  if (clashingBookings.length > 0) {
    window.alert(`There are existing bookings in the selected time slots. Move existing bookings and try again.
            ${clashingBookings.map(
              (booking) => `\n\nName: ${booking.name} Time: ${booking.slotDate}`,
            )}`);
    return false;
  }

  const deleteAndRecreateStatus = await deleteAndRecreateGroupBookings(
    newBooking,
    oldBookings,
    newBookings,
    allBookings,
    saveAsFrontAndBackBooking,
    gap,
  );
  if (!deleteAndRecreateStatus) {
    return false;
  }

  return await reorderDraftBookingsAfterMove(
    [...newBookings, ...tempNewFrontAndBack],
    oldBookings,
    allBookings,
  );
};

const deleteAndRecreateGroupBookings = async (
  booking: BookingData,
  oldBookings: BookingData[],
  newBookings: BookingData[],
  allBookings: BookingData[],
  saveAsFrontAndBackBooking: boolean,
  gap: number,
): Promise<boolean> => {
  const deleteStatus = await deleteAllLinkedBookings(booking);
  if (!deleteStatus) {
    return false;
  }

  const createStatus = await processCreateGroupBookings(
    newBookings,
    oldBookings,
    allBookings,
    saveAsFrontAndBackBooking,
    gap,
  );
  if (!createStatus) {
    return false;
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
};

export { createGroupBooking, dividePlayersIntoSlotTimes, editGroupBooking, moveGroupBooking };
