import {
  BookingData,
  BookingStatus,
  DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
  DeleteBookingsResponse,
  GetBookingsResponse,
  PostBookingResponse,
  Slot,
} from 'common';

import { GetBookings, convertRESTRepTOBookingData, marshallBookingData } from '../BookingsData';
import dayjs from '../dayjsWrapper';
import { Api } from './api';

export async function fetchBookingsByDay(day: dayjs.Dayjs): Promise<GetBookings> {
  const params = new URLSearchParams({
    day: day.format('DD-MM-YYYY').toString(),
  });

  return getBookings(params);
}

export async function fetchLinkedBookings(linkId: string): Promise<GetBookings> {
  const params = new URLSearchParams({
    linkId: linkId,
  });

  return getBookings(params);
}

const getBookings = async (params: URLSearchParams): Promise<GetBookings> => {
  try {
    const token = localStorage.getItem('ROCP_idToken');
    const response = await fetch(Api.bookings(params), {
      method: 'GET',
      headers: { ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }) },
    });
    const data: GetBookingsResponse = await response.json();

    if (data.error) {
      return { error: data.error };
    }

    const bookings = data.bookings?.map(convertRESTRepTOBookingData) || [];
    return { bookings };
  } catch (error) {
    return { error: String(error) };
  }
};

export async function createBooking(
  booking: BookingData,
  bookingSlotTimeSequence: number = DEFAULT_BOOKING_SLOT_TIME_SEQUENCE,
): Promise<boolean> {
  const token = localStorage.getItem('ROCP_idToken');
  const response = await fetch(Api.bookings(), {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      bookings: [marshallBookingData(booking, bookingSlotTimeSequence)],
    }),
  });

  if (response.status === 422) {
    const err: PostBookingResponse = await response.json();
    throw new Error(err.validationError?.toString());
  } else if (!response.ok) {
    const body = await response.json();
    throw new Error(body);
  }

  return response.ok;
}

export async function batchCreateBookings(bookings: BookingData[]): Promise<boolean> {
  const postBookings = bookings.map((booking) =>
    marshallBookingData(booking, booking.bookingSlotTimeSequence),
  );
  const token = localStorage.getItem('ROCP_idToken');
  const response = await fetch(Api.bookings(), {
    method: 'POST',
    headers: {
      ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ bookings: postBookings }),
  });

  if (response.status === 422) {
    const err: PostBookingResponse = await response.json();
    throw new Error(err.validationError?.toString());
  } else if (!response.ok) {
    const body = await response.json();
    throw new Error(body);
  }

  return response.ok;
}

export async function deleteBooking(
  date: Date,
  slot: Slot,
  bookingStatus: BookingStatus,
  bookingSlotTimeSequence: number,
): Promise<boolean> {
  const params = new URLSearchParams({
    dayTime: dayjs(date).utc(true).toISOString(),
    slot: slot.toString(),
    bookingStatus: bookingStatus,
    bookingSlotTimeSequence: bookingSlotTimeSequence.toString(),
  });

  const token = localStorage.getItem('ROCP_idToken');
  const response = await fetch(Api.bookings(params), {
    method: 'Delete',
    headers: { ...(token && { Authorization: `Bearer ${JSON.parse(token)}` }) },
  });

  if (!response.ok) {
    const err: DeleteBookingsResponse = await response.json();
    throw new Error(err.error);
  }

  return response.ok;
}
