import _ from 'lodash';

import {
  BookingData,
  BookingType,
  DRAFT_BOOKING,
  Hole,
  NO_LINK_ID,
  TIME_SLOT_SEPARATOR,
  isConfirmedBooking,
  isDraftBooking,
  isFrontAndBackBookingType,
  isNotEqual,
} from 'common';

import { generateKeyFromBooking, getSlotFromKey } from '../BookingsData';
import { createBooking, deleteBooking, fetchLinkedBookings } from '../clientAPI/bookingsAPI';
import {
  clashingSlot,
  getUpdatedBookingFields,
  isWithinOperatingHours,
} from '../helpers/bookingsHelper';
import { getBookingSlotTimeSequence, reorderDraftBookings } from './draftBookings';

/**
 * Fetch all bookings with the provided link ID
 *
 * @param linkId
 */
export const getLinkedBookings = async (linkId: string): Promise<BookingData[]> => {
  const result = await fetchLinkedBookings(linkId);
  if (!result.bookings) {
    return [] as BookingData[];
  }
  return result.bookings;
};

/**
 * Fetch all bookings with the provided link ID & sort them chronologically
 *
 * @param linkId
 */
export const getSortedLinkedBookings = async (linkId: string): Promise<BookingData[]> => {
  const linkedBookings: BookingData[] = await getLinkedBookings(linkId);
  return [...linkedBookings].sort((a, b) => (a.key < b.key ? -1 : 1));
};

/**
 * Copy the relevant data from the oldBooking to the newBooking
 *
 * @param oldBooking
 * @param newBooking
 */
export const copyFromOldToNewLinkedBooking = (
  oldBooking: BookingData,
  newBooking: BookingData,
): BookingData => {
  return {
    ...oldBooking,
    name: newBooking.name,
    paymentStatus: newBooking.paymentStatus,
    msg: newBooking.msg,
    email: newBooking.email,
    phone: newBooking.phone,
    org: newBooking.org,
    players: newBooking.players,
    hole: newBooking.hole,
    bookingStatus: newBooking.bookingStatus,
    infantPlayers: newBooking.infantPlayers,
    kidPlayers: newBooking.kidPlayers,
    adultPlayers: newBooking.adultPlayers,
    seniorPlayers: newBooking.seniorPlayers,
    totalPlayers: newBooking.totalPlayers,
    bookingsOrder: newBooking.bookingsOrder,
    bookingType: newBooking.bookingType,
    bookingSlotTimeSequence: newBooking.bookingSlotTimeSequence,
    invoices: newBooking.invoices,
    auditRecords: newBooking.auditRecords,
    slot: isFrontAndBackBookingType(oldBooking.bookingType) ? oldBooking.slot : newBooking.slot,
  };
};

/**
 * Delete a single linked booking (front & back or multiple)
 *
 * @param booking
 */
export const deleteSingleLinkedBooking = async (booking: BookingData): Promise<boolean> => {
  const deleteStatus = await deleteBooking(
    booking.slotDate,
    getSlotFromKey(booking),
    booking.bookingStatus,
    booking.bookingSlotTimeSequence,
  );
  if (!deleteStatus) {
    window.alert('Error deleting booking');
    return false;
  }

  const remainingFrontAndBackBookings: BookingData[] = await getLinkedBookings(booking.linkId);
  const onlyOneFrontAndBackBookingLeft = _.isEqual(remainingFrontAndBackBookings.length, 1);
  if (onlyOneFrontAndBackBookingLeft) {
    const remainingBooking = remainingFrontAndBackBookings[0];
    remainingBooking.hole = Hole.Nine;
    remainingBooking.linkId = NO_LINK_ID;
    const createStatus = await createBooking(remainingBooking);
    if (!createStatus) {
      window.alert('Error updating remaining booking');
      return false;
    }
  }

  // If we made it this far, all operations were successful, so return true.
  return true;
};

/**
 * Delete all linked bookings (front & back or multiple) related to the given booking
 *
 * @param booking
 */
export const deleteAllLinkedBookings = async (booking: BookingData): Promise<boolean> => {
  const frontAndBackBookings: BookingData[] = await getLinkedBookings(booking.linkId);
  try {
    const multiDeletes = frontAndBackBookings.map((booking) =>
      deleteBooking(
        booking.slotDate,
        booking.slot,
        booking.bookingStatus,
        booking.bookingSlotTimeSequence,
      ),
    );
    await Promise.all(multiDeletes);
    return true;
  } catch (error) {
    window.alert('Error deleting linked bookings');
    return false;
  }
};

/**
 * If the booking is a single booking then we delete that booking or
 * if it's a linked booking then we'll delete all the bookings associated with it.
 *
 * @param oldBooking
 * @param allBookings
 * @param isDraft
 */
export const cleanupLinkedBookings = async (
  oldBooking: BookingData,
  allBookings: BookingData[],
  isDraft: boolean,
): Promise<boolean> => {
  try {
    if (oldBooking.bookingType === BookingType.Single) {
      await deleteBooking(
        oldBooking.slotDate,
        oldBooking.slot,
        oldBooking.bookingStatus,
        oldBooking.bookingSlotTimeSequence,
      );
    } else {
      await deleteAllLinkedBookings(oldBooking);
    }

    // If it's a draft booking then we need to reorder them
    if (isDraft) {
      await reorderDraftBookings(oldBooking, allBookings);
    }

    return true;
  } catch (error) {
    window.alert('Cleanup existing bookings failed!');
    return false;
  }
};

/**
 * Move a linked booking to a different time/date/status
 *
 * @param newBookings
 * @param oldBookings
 * @param allBookings
 */
export const moveLinkedBookings = async (
  newBookings: BookingData[],
  oldBookings: BookingData[],
  allBookings: BookingData[],
) => {
  if (_.isEmpty(newBookings)) {
    return false;
  }

  // We need to delete the old bookings first in case only one of the linked bookings's key changed.
  // Otherwise, if we delete the old bookings after creating the new ones, we might end up with 2 bookings with the same key.
  // In that case deleting the old bookings would delete both of them.
  const deleteStatus = await deleteOldLinkedBookingBeforeMove(oldBookings);

  const createStatus = await createNewMovedLinkedBooking(newBookings, oldBookings, allBookings);

  const reorderStatus = await reorderDraftBookingsAfterMove(newBookings, oldBookings, allBookings);

  if (!(createStatus && deleteStatus && reorderStatus)) {
    window.alert('Error moving linked bookings');
    return false;
  }

  for (let i = 0; i < newBookings.length; i++) {
    if (!isWithinOperatingHours(newBookings[i])) {
      const affirm = window.confirm(
        'The new date/slot is outside of operating hours. Would you like to book anyway?',
      );
      if (affirm) break;
      if (!affirm) return false;
    }
  }

  return true;
};

/**
 * Construct the new moved linked booking.
 *
 * @param oldBooking
 * @param booking
 * @param newBookingTime
 */
export const buildNewMovedBooking = (
  oldBooking: BookingData,
  booking: BookingData,
  newBookingTime: Date,
): BookingData => {
  const newBooking = copyFromOldToNewLinkedBooking(oldBooking, booking);
  newBooking.slotDate = newBookingTime;
  newBooking.key = generateKeyFromBooking(newBooking);
  return newBooking;
};

/**
 * Before moving linked bookings we need to delete the old/existing bookings.
 *
 * @param oldBookings
 */
export const deleteOldLinkedBookingBeforeMove = async (
  oldBookings: BookingData[],
): Promise<boolean> => {
  for (const oldBooking of oldBookings) {
    const deleteStatus = await deleteBooking(
      oldBooking.slotDate,
      oldBooking.slot,
      oldBooking.bookingStatus,
      oldBooking.bookingSlotTimeSequence,
    );
    if (!deleteStatus) {
      return false;
    }
  }
  return true;
};

/**
 * Creates the new moved linked booking.
 *
 * @param newBookings
 * @param oldBookings
 * @param allBookings
 */
export const createNewMovedLinkedBooking = async (
  newBookings: BookingData[],
  oldBookings: BookingData[],
  allBookings: BookingData[],
): Promise<boolean> => {
  for (let i = 0; i < newBookings.length; i++) {
    // Here, we assume newBooking and oldBookings are sorted in accenting order as we
    // used the sortedOldBookings to generate newBookings
    const newBooking = newBookings[i];
    const oldBooking = oldBookings[i];

    const bookingSlotTimeSequence = getBookingSlotTimeSequence(newBooking, oldBooking, allBookings);

    const createStatus = await createBooking(newBooking, bookingSlotTimeSequence);
    if (!createStatus) {
      return false;
    }
  }
  return true;
};

/**
 * After moving a draft booking we need to re-order the remaining draft bookings.
 * This function will handle that.
 *
 * @param newBookings
 * @param oldBookings
 * @param allBookings
 */
export const reorderDraftBookingsAfterMove = async (
  newBookings: (BookingData | undefined)[],
  oldBookings: BookingData[],
  allBookings: BookingData[],
): Promise<boolean> => {
  for (let i = 0; i < oldBookings.length; i++) {
    // Here, we assume newBooking and oldBookings are sorted in accenting order as we
    // used the sortedOldBookings to generate newBookings
    const newBooking = newBookings[i];
    const oldBooking = oldBookings[i];

    if (!newBooking) {
      if (oldBooking.bookingStatus === DRAFT_BOOKING) {
        const reorderStatus = await reorderDraftBookings(oldBooking, allBookings);
        if (!reorderStatus) {
          return false;
        }
      }
      continue;
    }

    const reorderStatus = await reorderDraftBookingsIfNeeded(oldBooking, newBooking, allBookings);
    if (!reorderStatus) {
      return false;
    }
  }
  return true;
};

const reorderDraftBookingsIfNeeded = async (
  oldBooking: BookingData,
  newBooking: BookingData,
  allBookings: BookingData[],
): Promise<boolean> => {
  // We only need to reorder if a booking is moved out of draft.
  // Booking can be moved out of draft if a draft booking's date changes or
  // if a draft booking is converted to a confirmed booking.
  const updatedBookingFields = getUpdatedBookingFields(newBooking, oldBooking);

  const isDraft = isDraftBooking(newBooking.bookingStatus);
  const hasDateBeenUpdated = isNotEqual(
    oldBooking.key.split(TIME_SLOT_SEPARATOR)[0],
    newBooking.slotDate.toISOString(),
  );
  const bookingStatusChanged = 'bookingStatus' in updatedBookingFields;
  const changedToConfirmed = isConfirmedBooking(updatedBookingFields.bookingStatus);

  const draftDateChanged = hasDateBeenUpdated && isDraft;
  const draftChangedToConfirmed = bookingStatusChanged && changedToConfirmed;

  if (draftDateChanged || draftChangedToConfirmed) {
    const reorderStatus = await reorderDraftBookings(oldBooking, allBookings);
    if (!reorderStatus) {
      window.alert('Re-ordering draft bookings failed!');
      return false;
    }
  }

  return true;
};

export const getClashingBookings = async (
  bookings: BookingData[],
  oldBookings: BookingData[],
  allBookings?: BookingData[],
): Promise<BookingData[]> => {
  const clashingPromises = bookings.map(
    async (updatedBooking) => await clashingSlot(updatedBooking, allBookings),
  );

  const clashingBookings = _.compact(await Promise.all(clashingPromises));

  // We need to remove the `oldBooking`s from the returned array
  const removeBookingProperties = (booking: BookingData) =>
    _.omit(booking, ['rowSpan', 'invoices', 'auditRecords']);
  const filteredOldBookings = oldBookings.map(removeBookingProperties);
  return _.filter(clashingBookings, (booking) => {
    const filteredClashingBooking = removeBookingProperties(booking);
    return !filteredOldBookings.some((oldBooking) =>
      _.isEqual(filteredClashingBooking, oldBooking),
    );
  });
};
